<template lang="pug">
    component(
        v-if="isIcon"
        :is="type"
        :href="href"
        :style="iconStyles"
        :name="name"
        :target="target"
        :class="iconClasses"
    )
        .lh-article-card-category-icon__item
  </template>

<script lang="ts">
  // для отладки можно запускать в хроме с отключенным CORS

  // open -n -a /Applications/Google\ Chrome.app/Contents/MacOS/Google\ Chrome --args --user-data-dir="/tmp/chrome_dev_test" --disable-web-security
  import type { PropType } from 'vue';
  import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';

  export default defineNuxtComponent({
    name: 'ArticleCardCategoryIcon',
    components: {
      TheLinkUi,
    },
    props: {
      icon: {
        type: String as PropType<string>,
        required: true,
      },
      href: {
        type: String as PropType<string>,
        default: null,
      },
      name: {
        type: String as PropType<string>,
        required: false,
        default: null,
      },
      target: {
        type: String as PropType<string>,
        required: false,
        default: null,
      },
      size: {
        type: Number as PropType<number>,
        default: 32,
      },
      color: {
        type: String as PropType<string>,
        default: '#ED5E42',
      },
      colorHover: {
        type: String as PropType<string>,
        default: 'white',
      },
      background: {
        type: String as PropType<string>,
        default: 'white',
      },
      backgroundHover: {
        type: String as PropType<string>,
        default: '#ED5E42',
      },
    },
    computed: {
      isIcon(): boolean {
        return Boolean(this.icon);
      },
      isLink(): boolean {
        return Boolean(this.href);
      },
      iconClasses(): any {
        return {
          'lh-article-card-category-icon': true,
          'lh-article-card-category-icon--link': this.isLink,
        };
      },
      iconStyles(): any {
        return {
          '--mask-image': this.icon ? `url('${this.icon}')` : null,
          '--size': `${this.size}px`,
          '--color': this.color,
          '--colorHover': this.colorHover,
          '--background': this.background,
          '--backgroundHover': this.backgroundHover,
        };
      },
      type(): any {
        return this.isLink ? TheLinkUi : 'div';
      },
    },
  });
</script>

<style lang="scss" src="./ArticleCardCategoryIcon.scss"></style>
